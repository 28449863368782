<template>
  <div class="auth">
    <div class="wrapper email-unverified">
      <div class="content">
        <div class="logo">
          <h1>
            <app-i18n code="app.title"></app-i18n>
          </h1>
        </div>

        <h3 style="font-weight: normal; text-align: center">
          <app-i18n code="auth.registrationSuccess"></app-i18n>
        </h3>
        <router-link
          :to="{ path: '/auth/signin' }"
          style="font-weight: normal; text-align: center"
        >
          <el-button type="text">
            <app-i18n code="auth.backToSignin"></app-i18n>
          </el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-registration-success-page',
};
</script>